import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/templates/content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dissolving in Water`}</h1>
    <p>{`Substances dissolve in water in different ways: `}</p>
    <ul>
      <li parentName="ul">{`Ionic compounds dissolve by dissociation, where water surrounds the separated ions.`}</li>
      <li parentName="ul">{`Molecular compounds interact with water, but most do not dissociate.`}</li>
      <li parentName="ul">{`Some molecular substances react with water when they dissolve (acids are an example of this).`}</li>
    </ul>
    <h1>{`Electrolytes`}</h1>
    <p>{`Once dissolved, substances can be electrolytes or nonelectrolytes: `}</p>
    <ul>
      <li parentName="ul">{`An electrolyte is a substance that dissociates into ions when dissolved in water.`}</li>
      <li parentName="ul">{`A nonelectrolyte does not dissociate into ions when it does so.`}</li>
    </ul>
    <p>{`Strong acids and ionic compounds are strong electrolytes, weak acids and weak bases are weak electrolytes. All other compounds are nonelectrolytes. `}</p>
    <h1>{`Solubility`}</h1>
    <p>{`Memorize that all nitrates (NO3), acetates (C2H3O2), ammonium salts (NH4), and Group 1A metals are soluble. `}</p>
    <h1>{`Ways of Writing Reactions`}</h1>
    <p><strong parentName="p">{`Molecular equation`}</strong>{` — lists the reactants and products without indicating the ionic nature of the compounds. `}</p>
    <p><strong parentName="p">{`Complete ionic equation`}</strong>{` — all strong electrolytes (strong acids, strong bases, and soluble ionic salts) are dissociated into their ions. `}</p>
    <p><strong parentName="p">{`Net ionic equation`}</strong>{` — only lists ions that form the form the product, not `}<strong parentName="p">{`spectator ions`}</strong>{` that are present on both sides. `}</p>
    <h1>{`Double Replacement Reactions`}</h1>
    <ol>
      <li parentName="ol">{`Use the chemical formulas of the reactants to determine which ions are present. `}</li>
      <li parentName="ol">{`Write formulas for the products: cation from one reactant, anion from the other. Use charges to write proper subscripts. `}</li>
      <li parentName="ol">{`Check your solubility rules. If either product is insoluble, a precipitate forms. `}</li>
      <li parentName="ol">{`Balance the equation. `}</li>
    </ol>
    <p>{`When writing net ionic equations for double replacement reactions, dissociate all strong electrolytes (strong acids, ionic compounds) and cross out anything that remains unchanged from the reactants to the products. Precipitates do not dissociate into ions. `}</p>
    <h1>{`Acids`}</h1>
    <Callout mdxType="Callout">
  Memorize these strong acids: HCl, HBr, HI, HNO3, H2SO4, HClO3, HClO4
    </Callout>
    <p>{`These strong acids are strong electrolytes, and therefore dissociate completely in water. `}</p>
    <p>{`All other acids are weak acids, and do not dissociate completely in water. `}</p>
    <h1>{`Bases`}</h1>
    <p>{`All Group 1A and Group 2A (from calcium down) are strong bases. `}</p>
    <p>{`Strong bases break down into OH- in water, e.g. NaOH → Na+ + OH-. `}</p>
    <p>{`Metal oxides can also be considered bases, because they can combine with water to form a base, e.g. CaO + H2O → Ca(OH)2. `}</p>
    <h1>{`Acid-Base Reactions`}</h1>
    <p>{`These are a sub-class of double-replacement reactions. `}</p>
    <p>{`Reactions between an acid and a base are called `}<strong parentName="p">{`neutralization reactions`}</strong>{`. When a base is a metal hydroxide, `}<strong parentName="p">{`water`}</strong>{` and a `}<strong parentName="p">{`salt`}</strong>{` (an ionic compound) are produced. `}</p>
    <p>{`When a `}<strong parentName="p">{`strong acid`}</strong>{` reacts with a `}<strong parentName="p">{`strong base`}</strong>{`, both are able to dissociate. Therefore, the net ionic equation is always `}<inlineCode parentName="p">{`H+ (aq) + OH- (aq) → H2O (l)`}</inlineCode>{`.`}</p>
    <p>{`When a `}<strong parentName="p">{`weak acid`}</strong>{` reacts with a `}<strong parentName="p">{`strong base`}</strong>{` the net ionic equation will include nondissociated molecules. This is because the weak acid cannot be broken down into its ions, because it does not completely dissociate in water. `}</p>
    <h1>{`Single Replacement Reactions`}</h1>
    <p>{`Single replacement reactions occur when one element replaces another in a compound. `}</p>
    <Callout mdxType="Callout">
  When H2O splits into ions, it splits into H+ and OH- ions.
    </Callout>
    <p>{`Only elements higher on the `}<strong parentName="p">{`activity series`}</strong>{` can replace elements that are lower on it. You will be provided with the activity series list if needed. `}</p>
    <h1>{`Oxidation-Reduction Reactions`}</h1>
    <p>{`The loss of electrons is called `}<strong parentName="p">{`oxidation`}</strong>{`, while the gain of electrons is called `}<strong parentName="p">{`reduction`}</strong>{` (because its charge is reduced). One cannot occur without the other, and these reactions are often called `}<strong parentName="p">{`redox reactions`}</strong>{`. `}</p>
    <Callout mdxType="Callout">
  Single replacement reactions are always redox, while double replacement reactions are never redox.
    </Callout>
    <h2>{`Oxidation numbers`}</h2>
    <p>{`To determine if a redox reaction has occurred, we assign an `}<strong parentName="p">{`oxidation number`}</strong>{` to each substance. Oxidation numbers are the imaginary charges the atoms would have if they were ions. `}</p>
    <p>{`How to determine oxidation numbers: `}</p>
    <ul>
      <li parentName="ul">{`Oxidation numbers of pure elements are zero.`}</li>
      <li parentName="ul">{`Oxidation numbers of a monatomic ion is the same as its charge.`}</li>
      <li parentName="ul">{`Fluorine always an oxidation number of -1.`}</li>
      <li parentName="ul">{`Oxygen has an oxidation number of -2 in most compounds, except in peroxides (H2O2) where it is -1, and compounds with fluorine (HF2) where it is +2.`}</li>
      <li parentName="ul">{`Hydrogen is +1 when bonded to a nonmetal, and -1 when bonded to a metal.`}</li>
      <li parentName="ul">{`The sum of the oxidation numbers in a neutral compound is zero.`}</li>
      <li parentName="ul">{`The sum of the oxidation numbers in a polyatomic ion is the charge of the ion.`}</li>
    </ul>
    <p>{`After assigning oxidation numbers, one can look at the oxidation numbers of elements in the reactants and products: `}</p>
    <ul>
      <li parentName="ul">{`Increase in oxidation number = lost electron = oxidation`}</li>
      <li parentName="ul">{`Decrease in oxidation number = gain electron = reduction`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      